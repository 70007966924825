<template>
  <!-- 商品新增，修改 -->
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" :rules="rules" label-width="120px" ref="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" required prop="name">
              <el-input
                v-model="form.name"
                placeholder="商品名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="售价" required prop="discount_amount">
              <el-input v-model="form.discount_amount" placeholder="售价"></el-input>
            </el-form-item>
            <el-form-item label="原价" required prop="amount">
              <el-input
                v-model="form.amount"
                placeholder="原价"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型" required prop="type">
              <el-radio-group v-model="form.type">
                <el-radio label="coach_ip">教练个人ip</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面">
              <avatar-uploader
                ref="imgs"
                @getImgUrl="v=>form.cover_img = v"
                :url="form.cover_img"
              >
              </avatar-uploader>
            </el-form-item>
            
            <el-form-item label="介绍">
              <el-input
                type="textarea"
                v-model="form.introduce"
                placeholder="商品介绍"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍图片">
              <images-uploader
                ref="imgs"
                @getImgUrl="v=>form.introduce_img = v"
                :urls="form.introduce_img"
              >
              </images-uploader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="form.id" type="primary" @click="updateForm" :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary"  :loading="loading" @click="addForm">新 增</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
import cooperatorSelect from "../../../components/select/cooperatorSelect.vue";
import { addRule } from "./addRule.js";
export default {
  components: {
    citySelect,
    cooperatorSelect,
  },
  data() {
    return {
      rules: addRule,
      clear: false,
      form: {},
      temp: {},
      cityList: [],
      dialogVisible: false,
      coop_id: "",
      loading:false,
      cityoptions: JSON.parse(localStorage.getItem("addrList")),
      cityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
    };
  },

  mounted() {},

  methods: {
    addForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.type == "sub_agent") {
            this.form.parent_id = this.coop_id || null;
          }
          this.form.status = 1
          this.loading = true
          this.$axios({
            url: "/user/product/insert",
            method: "post",
            data: this.form,
          }).then((res) => {
            this.$notify({
              message: "新增成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            this.$emit("success", 1);
          }).catch(()=>{
            this.loading = false
          })
        }
      });
    },

    updateForm() {
      this.form.parent_id = this.coop_id || 0;
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true
          this.$axios({
            url: "/user/product/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          }).then((res) => {
            this.$notify({
              message: "更新成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            this.$emit("success", 1);
          }).catch(()=>{
            this.loading = false
          })
        }
      });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.cityList.push(info.province);
        this.cityList.push(info.city);
        this.cityList.push(info.district);
        this.coop_id = info.parent_id;
      } else {
        this.form = {};
        this.temp = {}
        this.cityList = []
        this.coop_id = "";
         
      }
      this.dialogVisible = true;
    },
    //选择地址
    changeMapAddr(addr) {
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
      this.$set(this.form, "address", addr.address);
      this.cityList.push(addr.province);
      this.cityList.push(addr.city);
      this.cityList.push(addr.district);
    },
    changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
  },
};
</script>

<style lang="less" scoped>
</style>